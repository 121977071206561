<template>
    <AlgoliaRefinementList
        class="algolia-toggle-list"
        :attribute="attribute"
        :label="label"
    >
        <template v-slot:item="{item, refine}">
            <HBtn
                class="toggle-item mb-3 mr-3"
                :style-type="item.isRefined ? 'primary' : 'secondary'"
                @click="refine(item.value)"
                :key="item.objectID"
                :data-cy="item.value"
            >
                {{ item.label }}
            </HBtn>
        </template>
    </AlgoliaRefinementList>
</template>

<script>
import AlgoliaRefinementList from './AlgoliaRefinementList'
import {
    HBtn
} from '@happytal/bo-ui-library'

export default {
    name: 'AlgoliaToggleList',
    props: {
        attribute: {
            type: String,
            required: true
        },
        label: {
            type: String,
            default: null
        }
    },
    components: {
        AlgoliaRefinementList,
        HBtn
    }
}
</script>

<style lang="scss" scoped>
@import '~@happytal/bo-ui-library/src/styles/variables';
.algolia-toggle-list {

    .items {

        .toggle-item {

        }
    }
}
</style>
