<template>
    <AisRefinementList
        class="algolia-refinement-list"
        :attribute="attribute"
        :searchable="searchable"
    >
        <template v-slot="{ items, refine, canRefine, searchForItems }">
            <div
                v-if="canRefine"
                class="header"
            >
                <div class="title">{{ label }}</div>
                <div class="actions">
                    <HBtn
                        v-if="opened"
                        icon-only
                        style-type="tertiary"
                        icon="MinusIcon"
                        @click="onToggleClick"
                    />
                    <HBtn
                        v-else
                        icon-only
                        style-type="tertiary"
                        icon="PlusIcon"
                        @click="onToggleClick"
                    />
                </div>
            </div>
            <div
                v-show="canRefine && opened"
                class="content"
            >
                <HInput
                    v-if="searchable"
                    class="search-input"
                    data-cy="search-input"
                    :placeholder="placeholder"
                    :value="search"
                    @input="search = $event; searchForItems($event)"
                />
                <div class="items">
                    <slot
                        name="item"
                        v-bind="{ item, refine, clear }"
                        v-for="item in sortedItems(items)"
                    />
                </div>
            </div>
        </template>
    </AisRefinementList>
</template>

<script>
import _ from 'lodash'
import {
    AisRefinementList
} from 'vue-instantsearch'
import {
    HInput,
    HBtn
} from '@happytal/bo-ui-library'

export default {
    name: 'AlgoliaRefinementList',
    props: {
        attribute: {
            type: String,
            required: true
        },
        label: {
            type: String,
            default: null
        },
        searchable: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: null
        }
    },
    components: {
        AisRefinementList,
        HInput,
        HBtn
    },
    data: () => ({
        search: null,
        opened: true
    }),
    methods: {
        onToggleClick (e) {
            this.opened = !this.opened
        },
        sortedItems (items) {
            return _.sortBy(items, ['label'])
        },
        clear () {
            this.search = null
        }
    }
}
</script>

<style lang="scss" scoped>
@import '~@happytal/bo-ui-library/src/styles/variables';
.algolia-refinement-list {

    .header {
        display: flex;
        align-items: center;
        padding: 20px 0px 0px 0px;
        border-top: 1px solid #EEEEEE;

        .title {
            flex-grow: 1;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            color: black;
        }
        .actions {

        }
    }
    .content {
        padding: 20px 0px 20px 0px;

        .search-input {

        }
        .items {

        }
    }
}
</style>
