<template>
    <ais-stats class="algolia-stats">
        <template v-slot="{ nbHits }">
            <div class="text-d">{{ nbHits }} résultat(s)</div>
        </template>
    </ais-stats>
</template>

<script>
import {
    AisStats
} from 'vue-instantsearch'

export default {
    name: 'AlgoliaStats',
    components: {
        AisStats
    }
}
</script>

<style lang="scss" scoped>
@import '~@happytal/bo-ui-library/src/styles/variables';
.algolia-stats {
    margin: 0px 0px 20px 0px;
}
</style>
