import { render, staticRenderFns } from "./AlgoliaRefinementList.vue?vue&type=template&id=0f711fcc&scoped=true&"
import script from "./AlgoliaRefinementList.vue?vue&type=script&lang=js&"
export * from "./AlgoliaRefinementList.vue?vue&type=script&lang=js&"
import style0 from "./AlgoliaRefinementList.vue?vue&type=style&index=0&id=0f711fcc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f711fcc",
  null
  
)

export default component.exports