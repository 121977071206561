













import Vue from 'vue'
import { HBtn } from '@happytal/bo-ui-library'
export default Vue.extend({
    components: {
        HBtn
    },

    data(){
        return {
            isVisible: false
        }
    },

    mounted(){
        window.addEventListener('scroll', this.handleBtnVisibility)
    },

    beforeDestroy() {
        window.removeEventListener('scroll', this.handleBtnVisibility)
    },

    methods: {
        handleBtnVisibility(){
            if (window.scrollY > 200) {
                this.isVisible = true
            } else {
                this.isVisible = false
            }
        },

        scrollToTop() {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            })
        }
    }
})
