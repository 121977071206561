
































import Vue, { PropType } from 'vue'

import { ProductItem } from '@/types/components'

export default Vue.extend({
    props: {
        product: {
            type: Object as PropType<ProductItem>
        },
        displayPcp: {
            type: Boolean,
            default: false
        }
    }
})
