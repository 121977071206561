import {
    normalizeText
} from '@/modules/strings'

function getProductHtmlColor (textColor) {
    textColor = normalizeText(textColor)
    switch (textColor) {
        case 'bleu':
            return '#3479BE'
        case 'blanc':
            return '#FFFFFF'
        case 'rose':
            return '#E892BD'
        case 'marron':
            return '#654028'
        case 'gris':
            return '#CCCCCC'
        case 'beige':
            return '#EED4BA'
        case 'rouge':
            return '#CC3201'
        case 'vert':
            return '#4CA630'
        case 'violet':
            return '#80007F'
        case 'orange':
            return '#EA8F3A'
        case 'jaune':
            return '#FAD95E'
        case 'bleu marine':
            return '#163F68'
        case 'bordeaux':
            return '#6D071A'
        case 'bleu clair':
            return '#98CDEC'
        case 'kaki':
            return '#94812B'
        case 'turquoise':
            return '#25FDE5'
        case 'corail':
            return '#E55E5B'
        case 'creme':
            return '#FFE6CD'
        case 'noir':
        default:
            return '#000000'
    }

}
export {
    getProductHtmlColor
}
