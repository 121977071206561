<template>
    <ais-instant-search
        class="algolia-instant-search"
        :index-name="indexName"
        v-bind:search-client="searchClient"
        :routing="routing"
    >
        <slot />
    </ais-instant-search>
</template>

<script>
import algoliasearch from 'algoliasearch'
import {
    AisInstantSearch
} from 'vue-instantsearch'

import vueRouter from '@/router'
import appsettings from '@/appsettings'
import vuexStore from '@/store/store'

const vueAppTestMode = appsettings.VUE_APP_TEST_MODE
const vueAppAlgoliaIndexName = appsettings.VUE_APP_ALGOLIA_INDEX_NAME
const vueAppAlgoliaApplicationId = appsettings.VUE_APP_ALGOLIA_APPLICATION_ID
const vueAppAlgoliaApiKey = appsettings.VUE_APP_ALGOLIA_API_KEY

function createAlgoliaSearchClient(appId, apiKey) {
    if (vueAppTestMode) {
        return algoliasearch(appId, apiKey, {
            hosts: [{ url: 'algolia-api-mock' }],
        })
    }
    else {
        return algoliasearch(appId, apiKey)
    }
}

export default {
    name: 'AlgoliaInstantSearch',
    components: {
        AisInstantSearch
    },
    data () {
        return {
            indexName: vueAppAlgoliaIndexName,
            searchClient: createAlgoliaSearchClient(
                vueAppAlgoliaApplicationId,
                vueAppAlgoliaApiKey
            ),
            routing: {
                stateMapping: {
                    stateToRoute (uiState) {
                        const indexUiState = uiState[vueAppAlgoliaIndexName]
                        //console.log('stateToRoute', indexUiState)
                        return {
                            query: indexUiState.query,
                            hierarchicalMenu: _.get(indexUiState, 'hierarchicalMenu'),
                            brandLabel: _.get(indexUiState, 'refinementList.brandLabel'),
                            color: _.get(indexUiState, 'refinementList.color'),
                            size: _.get(indexUiState, 'refinementList.size'),
                            sortBy: _.get(indexUiState, 'sortBy'),
                            price: _.get(indexUiState, 'range.price'),
                            //institutions: _.get(indexUiState, 'refinementList.institutions'),
                            page: indexUiState.page
                        }
                    },
                    routeToState (routeState) {
                        let institutionId = vuexStore.getters['dataUser/getInstitutionId']()
                        //console.log('routeToState:before', routeState)
                        const page = routeState.page
                        const state = {
                            [vueAppAlgoliaIndexName]: {
                                query: routeState.query,
                                range: {
                                    price: routeState.price
                                },
                                sortBy: routeState.sortBy,
                                refinementList: {
                                    brandLabel: routeState.brandLabel,
                                    color: routeState.color,
                                    size: routeState.size,
                                    institutions: [0, institutionId]
                                },
                                page: page ? parseInt(page, 10) : 0
                            }
                        }
                        if (routeState.hierarchicalMenu) {
                            state[vueAppAlgoliaIndexName].hierarchicalMenu = routeState.hierarchicalMenu
                        }
                        const categoryId = _.get(vueRouter, 'currentRoute.params.category_id', null)
                        if (categoryId) {
                            const menuKey = 'categories.level0'
                            const pathCategories = vuexStore.getters['dataCatalogCategories/getCategoryPathById'](categoryId)
                            state[vueAppAlgoliaIndexName].hierarchicalMenu = {}
                            state[vueAppAlgoliaIndexName].hierarchicalMenu[menuKey] = _.map(pathCategories, (category) => {
                                return category.name
                            })
                        }
                        //console.log('routeToState:final', state)
                        return state
                    }
                },
                router: {
                    read () {
                        return vueRouter.currentRoute.query
                    },
                    write (routeState) {
                        if (!this._disposed) {
                            vueRouter.push({
                                query: routeState,
                            })
                        }
                    },
                    createURL (routeState) {
                        return vueRouter.resolve({
                            query: routeState,
                        }).href
                    },
                    onUpdate (cb) {
                        this._disposed = false
                        // Store Algolia callback to use it later
                        this._cb = cb
                        this._onPopState = event => {
                            const routeState = event.state
                            if (!routeState) {
                                cb(this.read())
                            }
                            else {
                                cb(routeState)
                            }
                        }
                        window.addEventListener('popstate', this._onPopState)
                    },
                    dispose () {
                        this._disposed = true
                        if (typeof window === 'undefined') {
                            return
                        }
                        if (this._onPopState) {
                            window.removeEventListener('popstate', this._onPopState)
                        }
                        if (this._removeAfterEach) {
                            this._removeAfterEach()
                        }
                    }
                }
            }
        }
    },
    watch: {
        $route (newValue, oldValue) {
            // Notify Algolia to change its state when current url change
            // This fix was made for detect route 'page' parameter change
            this.routing.router._cb(newValue.query)
        }
    }
}
</script>

<style lang="scss" scoped>
@import '~@happytal/bo-ui-library/src/styles/variables';
.algolia-instant-search {

}
</style>
