<template>
    <AlgoliaRefinementList
        class="algolia-checkbox-list"
        :attribute="attribute"
        :searchable="true"
        :placeholder="placeholder"
        :label="label"
        :data-cy="`filter-${attribute}`"
    >
        <template v-slot:item="{ item, refine, clear }">
            <div
                class="list-item"
                :key="item.objectID"
            >
                <HCheckbox
                    class="checkbox"
                    :data-cy="item.value"
                    :modelValue="item.isRefined"
                    @change="clear(); refine(item.value)"
                >
                    {{ item.label }}
                </HCheckbox>
                <div
                    class="badge"
                    :class="{ 'badge-selected': item.isRefined }"
                >{{ item.count }}</div>
            </div>
        </template>
    </AlgoliaRefinementList>
</template>

<script>
import AlgoliaRefinementList from './AlgoliaRefinementList'
import {
    HCheckbox
} from '@happytal/bo-ui-library'

export default {
    name: 'AlgoliaCheckboxList',
    props: {
        attribute: {
            type: String,
            required: true
        },
        label: {
            type: String,
            default: null
        },
        placeholder: {
            type: String,
            default: null
        }
    },
    components: {
        AlgoliaRefinementList,
        HCheckbox
    }
}
</script>

<style lang="scss" scoped>
@import '~@happytal/bo-ui-library/src/styles/variables';
.algolia-checkbox-list {

    .list-item {
        display: flex;
        align-items: center;

        .checkbox {
            min-height: auto;
        }
        .badge {
            display: inline-block;
            margin: 0px 0px 0px 10px;
            padding: 4px 9px 4px 9px;
            border-radius: 18px;
            font-size: 12px;
            font-weight: 600;
            background-color: rgba(197,201,224,.2);
            color: #848ab8;
        }
        .badge-selected {
            background-color: var(--v-primary-base);
            color: white;
        }
    }
}
</style>
