import Routes from '@/constants/routes'

const CATALOG_ROUTES = [
    Routes.ResidentCatalog,
    Routes.CatalogProduct,
    Routes.CatalogVariant,
    Routes.CatalogService
]

export default {
    methods: {
        getResidentById (residentId) {
            return this.$store.getters['dataResidents/getResidentById'](residentId)
        },
        onPcpSidebarAction (action) {
            this.showPcpSidebar(false)
            const pcp = this.getCurrentPcp()
            const products = _.map(pcp.shoppingListProducts, (product) => {
                return _.omit(product, ['meta'])
            })
            this.$store.dispatch('dataPcps/updatePcp', {
                pcpId: pcp.id,
                products,
                services: pcp.shoppingListServices
            })
            .then(() => {
                Promise.all([
                    this.$store.dispatch('dataResidents/getResidents', {
                        userId: this.getUserId(),
                        institutionId: this.getInstitutionId()
                    }),
                    this.$store.dispatch('dataPcps/getResidentPcp', {
                        residentId: this.resident_id
                    })
                ])
                .then(([_, pcpApi]) => {
                    //console.log('pcp:api:ok', pcpApi)
                    switch (action) {
                        case 'validate':
                            this.goToPcp()
                            break
                        case 'buy':
                            const resident = this.getResidentById(this.resident_id)
                            const lastName = resident.lastName.toUpperCase()
                            const firstName = resident.firstName
                            this.$vs.dialog({
                                type: 'alert',
                                color: 'primary',
                                title: `Moyen de paiement`,
                                text: `Le mode de paiement pour ${lastName} ${firstName} n'est pas renseigné.`,
                                acceptText: `Renseigner`,
                                accept: this.goToPayment
                            })
                            break
                    }
                })
                .catch((error) => {
                    //console.log('pcp:api:error', error)
                })
            })
            .catch((error) => {
                this.showErrorToast(`Impossible de valider le PCP (${error}).`)
            })
        },
        goToPcp () {
            this.$router.push({
                name: Routes.ResidentSubscriptionsCurrent,
                params: {
                    resident_id: this.resident_id
                }
            })
        },
        goToPayment () {
            this.$router.push({
                name: Routes.ResidentPayment,
                params: {
                    resident_id: this.resident_id
                }
            })
        }
    },
    beforeRouteLeave (to, from, next) {
        if (CATALOG_ROUTES.includes(to.name)) {
            next()
            return
        }
        const pcp = this.getCurrentPcp()
        const hasItemsNotValidated = pcp && (pcp.shoppingListProducts.some(product => !product.meta.validated) || pcp.shoppingListServices.some(service => !service.meta.validated))
        if (!hasItemsNotValidated) {
            next()
            return
        }
        this.$vs.dialog({
            type: 'confirm',
            color: 'primary',
            title: `Annuler les modifications ?`,
            text: `Êtes-vous sûr de vouloir quitter la page ? Les produits et services modifiés ne seront pas enregistrés dans l'abonnement du résident.`,
            acceptText: `Oui`,
            cancelText: `Non`,
            accept: () => {
                this.$store.dispatch('dataPcps/getResidentPcp', {
                    residentId: this.resident_id
                })
                .then((pcpApi) => {
                    //console.log('pcp:api:ok', pcpApi)
                    next()
                })
                .catch((error) => {
                    //console.log('pcp:api:error', null)
                })
            },
            cancel: () => {
                next(false)
            }
        })
    }
}
