<template>
    <div
        class="algolia-autocomplete"
        ref="autocompleteContainer"
        data-cy="catalog-search-input"
    ></div>
</template>

<script>
import _ from 'lodash'
import Vue from 'vue'
import { createWidgetMixin } from 'vue-instantsearch/vue2/es'
import { connectSearchBox } from 'instantsearch.js/es/connectors'
import { autocomplete } from '@algolia/autocomplete-js'
import '@algolia/autocomplete-theme-classic'
import { createLocalStorageRecentSearchesPlugin } from '@algolia/autocomplete-plugin-recent-searches'
import { createQuerySuggestionsPlugin } from '@algolia/autocomplete-plugin-query-suggestions'

import appsettings from '@/appsettings'

import Routes from '@/constants/routes'

const vueAppAlgoliaIndexName = appsettings.VUE_APP_ALGOLIA_INDEX_NAME
const vueAppAlgoliaSuggestionsIndexName = appsettings.VUE_APP_ALGOLIA_SUGGESTIONS_INDEX_NAME

export default Vue.extend({
    name: 'AlgoliaAutocomplete',
    mixins: [createWidgetMixin({
        connector: connectSearchBox
    })],
    props: {
        placeholder: {
            type: String,
            default: null
        },
        searchOnType: {
            type: Boolean,
            default: false
        },
        externalSearch: {
            type: Boolean,
            default: true
        },
        globalSearch: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            autocompleteInstance: null
        }
    },
    methods: {
        sendChange (search) {
            this.$emit('change', search)
        },
        redirectToResultPage (query) {
            this.$router.push({
                name: Routes.AlgoliaProductSearch,
                query: {
                    query
                }
            })
        },
        setInstantSearchUiState ({ query }) {
            const self = this
            this.instantSearchInstance.setUiState((uiState) => {
                //console.log('uiState:start', uiState)

                const originalState = uiState[vueAppAlgoliaIndexName]
                let finalUiState = {}
                if (self.globalSearch) {
                    // In global search mode we keep
                    // only query and institutions
                    // search parameters
                    finalUiState = {
                        refinementList: {
                            institutions: originalState.institutions
                        }
                    }
                }
                else {
                    finalUiState = originalState
                }
                //console.log('uiState:end', finalUiState)

                return {
                    ...uiState,
                    [vueAppAlgoliaIndexName]: {
                        ...finalUiState,
                        page: 1,
                        query
                    }
                }
            })
        },
        reset () {
            this.autocompleteInstance.setQuery('')
            this.setInstantSearchUiState({
                query: ''
            })
        }
    },
    mounted () {
        const { instantSearchInstance } = this

        const self = this
        let initialState = instantSearchInstance.mainIndex.getHelper()?.state || {}

        const recentSearchesPlugin = createLocalStorageRecentSearchesPlugin({
            key: 'search',
            limit: 3,
            transformSource ({ source }) {
                return {
                    ...source,
                    onSelect ({ item }) {
                        if (self.externalSearch) {
                            self.redirectToResultPage(item.label)
                        }
                        else {
                            self.setInstantSearchUiState({
                                query: item.label
                            })
                            self.sendChange(item.label)
                        }
                    }
                }
            }
        })

        const querySuggestionsPlugin = createQuerySuggestionsPlugin({
            searchClient: instantSearchInstance.client,
            indexName: vueAppAlgoliaSuggestionsIndexName,
            getSearchParams () {
                // This creates a shared `hitsPerPage` value once the duplicates
                // between recent searches and Query Suggestions are removed
                return recentSearchesPlugin.data.getAlgoliaSearchParams({
                    hitsPerPage: 6
                })
            },
            transformSource ({ source }) {
                return {
                    ...source,
                    sourceId: 'querySuggestionsPlugin',
                    onSelect ({ item }) {
                        self.setInstantSearchUiState({
                            query: item.query
                        })
                        self.sendChange(item.query)
                    },
                    getItems (params) {
                        // We don't display Query Suggestions when there's no query
                        if (!params.state.query) {
                            return []
                        }
                        return source.getItems(params)
                    }
                }
            }
        })

        this.autocompleteInstance = autocomplete({
            container: this.$refs.autocompleteContainer,
            openOnFocus: true,
            placeholder: this.placeholder,
            detachedMediaQuery: 'none',
            initialState: {
                query: initialState.query || ''
            },
            plugins: [
                recentSearchesPlugin,
                querySuggestionsPlugin
            ],
            onSubmit ({ state }) {
                if (self.$gtm.enabled()) {
                    self.$gtm.trackEvent({
                        event: 'search_catalog',
                        search: state.query
                    })
                }
                if (self.externalSearch) {
                    if (!state.query) {
                        return
                    }
                    self.redirectToResultPage(state.query)
                }
                else {
                    self.setInstantSearchUiState({
                        query: state.query
                    })
                    self.sendChange(state.query)
                }
            },
            onReset () {
                self.setInstantSearchUiState({
                    query: ''
                })
                self.sendChange('')
            },
            onStateChange ({ prevState, state }) {
                //console.log('state-change', state)
                if (self.searchOnType && prevState.query !== state.query) {
                    self.setInstantSearchUiState({
                        query: state.querysearchOnType
                    })
                    self.sendChange(state.query)
                }
            }
        })
    },
    beforeDestroy () {
        this.autocompleteInstance?.destroy()
    }
})
</script>

<style lang="scss">
@import '~@happytal/bo-ui-library/src/styles/variables';

.aa-Panel {
    z-index: 2;
}

.algolia-autocomplete {
    display: flex;

    .aa-Autocomplete {
        margin: 10px 0px 10px 0px;

        .aa-Form {
            border-radius: 25px;

            .aa-SubmitIcon {
                color: var(--v-primary-base);
            }
        }
        .aa-Form:focus-within {
            border-color: var(--v-primary-base);
            box-shadow: none;
        }
    }
}
</style>
