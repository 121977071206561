<template>
    <div class="algolia-sort-by">
        <div class="label">{{ label }}</div>
        <ais-sort-by
            class="sort-selector"
            :items="sortItems"
        >
            <template v-slot="{ items, currentRefinement, refine }">
                <HSelect
                    :value="getOptionValue(currentRefinement)"
                    :options="items"
                    :searchable="false"
                    :show-labels="true"
                    label="label"
                    track-by="value"
                    data-cy="sort-by-select"
                    placeholder="Choisir un tri"
                    @input="(item) => {onSortChange(item, refine)}"
                />
            </template>
        </ais-sort-by>
    </div>
</template>

<script>
import {
    AisSortBy
} from 'vue-instantsearch'
import {
    HSelect
} from '@happytal/bo-ui-library'

export default {
    name: 'AlgoliaSortBy',
    props: {
        sortItems: {
            type: Array,
            required: true
        },
        label: {
            type: String
        }
    },
    components: {
        AisSortBy,
        HSelect
    },
    methods: {
        getOptionValue (value) {
            return _.find(this.sortItems, (item) => {
                return item.value == value
            })
        },
        onSortChange (item, refine) {
            const value = _.get(item, 'value', null)
            refine(value)
            this.$emit('change', value)
        }
    }
}
</script>

<style lang="scss" scoped>
@import '~@happytal/bo-ui-library/src/styles/variables';
.algolia-sort-by {
    display: flex;
    align-items: center;

    .label {
        margin: 0px 20px 0px 0px;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: black;
    }
    .sort-selector {
        width: 200px;
    }
}
</style>
