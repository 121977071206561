<template>
    <AisRefinementList
        class="algolia-refinement-list-mask"
        :attribute="attribute"
        :searchable="false"
    >
        <template v-slot:default>
            <div class="refinement-list"></div>
        </template>
    </AisRefinementList>
</template>

<script>
import {
    AisRefinementList
} from 'vue-instantsearch'

export default {
    name: 'AlgoliaRefinementListMask',
    components: {
        AisRefinementList
    },
    props: {
        attribute: {
            type: String,
            required: true
        }
    }
}
</script>

<style lang="scss" scoped>
@import '~@happytal/bo-ui-library/src/styles/variables';
.algolia-refinement-list-mask {

    .refinement-list {

    }
}
</style>
