<template>
    <ais-pagination
        class="algolia-pagination"
    >
        <template v-slot="{ refine, nbPages, currentRefinement }">
            <div class="pagination-pages">
                <HPagination
                    v-if="nbPages > 1"
                    :value="currentRefinement + 1"
                    :length="nbPages"
                    :total-visible="8"
                    circle
                    class="mt-6"
                    @input="(page) => {onPageChange(page, refine)}"
                />
            </div>
        </template>
    </ais-pagination>
</template>

<script>
import {
    AisPagination
} from 'vue-instantsearch'
import {
    HPagination
} from '@happytal/bo-ui-library'

export default {
    name: 'AlgoliaPagination',
    components: {
        AisPagination,
        HPagination
    },
    methods: {
        onPageChange (page, refine) {
            refine(page - 1)
            this.$emit('change', page)
        }
    }
}
</script>

<style lang="scss" scoped>
@import '~@happytal/bo-ui-library/src/styles/variables';
.algolia-pagination {

    .pagination-pages {

    }
}
</style>
