<template>
    <AlgoliaRefinementList
        class="algolia-color-list"
        :attribute="attribute"
        :label="label"
        :data-cy="`filter-${attribute}`"
    >
        <template v-slot:item="{ item, refine }">
            <div
                class="color-item"
                :class="{ 'color-item-selected' : item.isRefined }"
                @click="refine(item.value)"
                :key="item.objectID"
                :data-cy="item.value"
            >
                <div
                    :class="getItemClasses(item)"
                    :style="getItemStyles(item)"
                ></div>
                <div class="label">{{ item.label }}</div>
            </div>
        </template>
    </AlgoliaRefinementList>
</template>

<script>
import {
    normalizeText
} from '@/modules/strings'
import {
    getProductHtmlColor
} from '@/modules/colors'

import AlgoliaRefinementList from './AlgoliaRefinementList'

export default {
    name: 'AlgoliaColorList',
    props: {
        attribute: {
            type: String,
            required: true
        },
        label: {
            type: String,
            default: null
        }
    },
    components: {
        AlgoliaRefinementList
    },
    methods: {
        getItemClasses (item) {
            let color = normalizeText(item.value)
            switch (color) {
                case 'dore':
                case 'argente':
                case 'multicolore':
                    return `color color-${color}`
                default:
                    return `color`
            }
        },
        getItemStyles (item) {
            let color = normalizeText(item.value)
            switch (color) {
                case 'dore':
                case 'argente':
                case 'multicolore':
                    return ``
                default:
                    color = getProductHtmlColor(item.value)
                    return `background: ${color};`
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '~@happytal/bo-ui-library/src/styles/variables';
.algolia-color-list {

    .items {
        text-align: center;

        .color-item {
            display: inline-block;
            margin: 0px 18px 18px 0px;
            cursor: pointer;

            .color {
                width: 55px;
                height: 55px;
                border-radius: 30px;
            }
            .color-dore {
                background-position: center;
                background-image: url('../../../assets/images/products/dore.png')
            }
            .color-argente {
                background-position: center;
                background-image: url('../../../assets/images/products/argente.png')
            }
            .color-multicolore {
                background-position: center;
                background-image: url('../../../assets/images/products/multicolore.png')
            }
            .label {
                margin: 4px 0px 0px 0px;
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                text-align: center;
                color: black;
            }
        }
        .color-item-selected {

            .color {
                border: 2px solid var(--v-primary-base);
            }
            .label {
                font-weight: 600;
                color: var(--v-primary-base);
            }
        }
    }
}
</style>
