<template>
    <ais-range-input
        class="algolia-range"
        :attribute="attribute"
    >
        <template v-slot="{ currentRefinement, range, canRefine, refine }">
            <div
                v-if="canRefine"
                class="header"
            >
                <div class="title">{{ label }}</div>
                <div class="actions">
                    <HBtn
                        v-if="opened"
                        icon-only
                        style-type="tertiary"
                        icon="MinusIcon"
                        data-cy="minus-btn"
                        @click="onToggleClick"
                    />
                    <HBtn
                        v-else
                        icon-only
                        style-type="tertiary"
                        icon="PlusIcon"
                        data-cy="plus-btn"
                        @click="onToggleClick"
                    />
                </div>
            </div>
            <div
                v-show="canRefine && opened"
                class="content"
            >
                <div class="from-label">De</div>
                <HInput
                    class="range-min-input"
                    data-cy="range-min-input"
                    type="number"
                    :min="range.min"
                    :max="range.max"
                    :placeholder="range.min"
                    :value="formatMinValue(currentRefinement.min, range.min)"
                    @input="(value) => {onMinChange(value, currentRefinement, refine, range)}"
                    :help-text="`Min ${range.min}${unit}`"
                />
                <div class="to-label">à</div>
                <HInput
                    class="range-max-input"
                    data-cy="range-max-input"
                    type="number"
                    :min="range.min"
                    :max="range.max"
                    :placeholder="range.max"
                    :disabled="!canRefine"
                    :value="formatMaxValue(currentRefinement.max, range.max)"
                    @input="(value) => {onMaxChange(value, currentRefinement, refine, range)}"
                    :help-text="`Max ${range.max}${unit}`"
                />
            </div>
        </template>
    </ais-range-input>
</template>

<script>
import {
    AisRangeInput
} from 'vue-instantsearch'
import {
    HInput,
    HBtn
} from '@happytal/bo-ui-library'

export default {
    name: 'AlgoliaRange',
    props: {
        attribute: {
            type: String,
            required: true
        },
        label: {
            type: String,
            default: null
        },
        unit: {
            type: String,
            default: '€'
        }
    },
    components: {
        AisRangeInput,
        HInput,
        HBtn
    },
    data: () => ({
        opened: true
    }),
    methods: {
        onMinChange (value, currentRefinement, refine, range) {
            refine({
                min: value,
                max: this.formatMaxValue(currentRefinement.max, range.max),
            })
        },
        formatMinValue (minValue, minRange) {
            return minValue && minValue !== minRange ? `${minValue}` : ''
        },
        onMaxChange (value, currentRefinement, refine, range) {
            refine({
                min: this.formatMaxValue(currentRefinement.min, range.min),
                max: value
            })
        },
        formatMaxValue (maxValue, maxRange) {
            return maxValue && maxValue !== maxRange ? `${maxValue}` : ''
        },
        onToggleClick (e) {
            this.opened = !this.opened
        }
    }
}
</script>

<style lang="scss" scoped>
@import '~@happytal/bo-ui-library/src/styles/variables';
.algolia-range {

    .header {
        display: flex;
        align-items: center;
        border-top: 1px solid #EEEEEE;
        padding: 20px 0px 0px 0px;

        .title {
            flex-grow: 1;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            color: black;
        }
        .actions {

        }
    }
    .content {
        display: flex;
        padding: 20px 0px 40px 0px;

        .from-label {
            margin: 8px 0px 0px 0px;
        }
        .range-min-input {
            margin: 0px 0px 0px 8px;
            width: 75px;
        }
        .to-label {
            margin: 8px 0px 0px 8px;
        }
        .range-max-input {
            margin: 0px 0px 0px 8px;
            width: 75px;
        }
    }
}
</style>
